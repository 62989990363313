import { useEffect, useState } from 'react';
import './imagescroll.css';


export const ImageScrollAboutLeft = () => {
    const imgs = [
        null,
        'https://aeroaccessories.us/wp-content/uploads/2024/03/SELECTS-1-16-09-06-1536x864.png',
        null,
    ];
    const percents = [0, 70, 100];
    percents.reverse();
    imgs.reverse();
    return (<ImageScroll imgs={imgs} percents={percents} />);
}

export const ImageScrollAboutRight = () => {
    const imgs = [
        'https://aeroaccessories.us/wp-content/uploads/2024/03/SELECTS-1-18-31-11-1536x864.png',
        null,
        'https://aeroaccessories.us/wp-content/uploads/2024/03/SELECTS-1-18-43-17-1536x864.png',
    ];
    const percents = [0, 70, 100];
    percents.reverse();
    imgs.reverse();
    return (<ImageScroll imgs={imgs} percents={percents} />);
}

export const ImageScrollSolutions = () => {

    const imgs = [
        'https://aeroaccessories.us/wp-content/uploads/2024/03/Worker-V1.png',
        'https://aeroaccessories.us/wp-content/uploads/2024/03/worker-Screen.png',
        'https://aeroaccessories.us/wp-content/uploads/2024/03/SELECTS-1-03-25-08.png',
        'https://aeroaccessories.us/wp-content/uploads/2024/03/SELECTS-1-08-34-07.png',
        'https://aeroaccessories.us/wp-content/uploads/2024/03/SELECTS-1-09-29-04.png',
        'https://aeroaccessories.us/wp-content/uploads/2024/03/SELECTS-1-11-45-11.png'
    ];
    const percents = [0, 20, 40, 60, 80, 100];
    percents.reverse();
    imgs.reverse();
    return (<ImageScroll imgs={imgs} percents={percents} />);
}
export const ImageScroll = ({ imgs, percents }) => {

    const [offset, setOffset] = useState(0);

    useEffect(() => {
        const el = document.querySelector('#reactscrollcontainer');
        // const top = el.getBoundingClientRect().top;

        function handleScroll(e) {
            const top = el.getBoundingClientRect().top + window.scrollY;
            const height = el.getBoundingClientRect().height;
            const scroll = (window.scrollY) + window.innerHeight;
            const pos = scroll - top;
            const perc = Math.round((pos / height) * 100);
            console.log({ top, height, scroll, perc });
            setOffset(perc);
        }

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    const getImage = () => {

        let i = 0;
        for (i = 0; i < percents.length; i++) {
            if (offset > percents[i]) {
                break;
            }
        }


        return imgs.map((img, idx) => {
            if (img == null) {
                return <></>
            }
            return <img src={img} key={idx} className={`imagescrollimage ${idx == i ? '' : 'fade-out'}`} ></img>
        })

    }

    return (
        <>
            {getImage()}
        </>
    )
}