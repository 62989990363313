import { createRoot } from 'react-dom/client';
import { GridExample } from './table';
import { SearchExample } from './search';
import { ContactForm } from './contactform';
import { ContactForm2 } from './contactform2';
import { ImageScrollSolutions, ImageScrollAboutLeft, ImageScrollAboutRight } from './imagescroll';
import { Carousel } from './carousel';


if (document.getElementById('reacttable')) {
    createRoot(document.getElementById('reacttable')).render(<GridExample />);
}

if (document.getElementById('reactsearch1')) {
    createRoot(document.getElementById('reactsearch1')).render(<SearchExample variant='standard' />);;
}

if (document.getElementById('reactsearch2')) {
    createRoot(document.getElementById('reactsearch2')).render(<SearchExample variant='outlined' />);
}
if (document.getElementById('reactcontactform')) {
    createRoot(document.getElementById('reactcontactform')).render(<ContactForm />);
}
if (document.getElementById('reactcontactform2')) {
    createRoot(document.getElementById('reactcontactform2')).render(<ContactForm2 />);
}
if (document.getElementById('reactimagescroll')) {
    createRoot(document.getElementById('reactimagescroll')).render(<ImageScrollSolutions />);
}
if (document.getElementById('reactimagescrollaboutleft')) {
    createRoot(document.getElementById('reactimagescrollaboutleft')).render(<ImageScrollAboutLeft />);
}
if (document.getElementById('reactimagescrollaboutright')) {
    createRoot(document.getElementById('reactimagescrollaboutright')).render(<ImageScrollAboutRight />);
}
if (document.getElementById('reactcarousel')) {
    createRoot(document.getElementById('reactcarousel')).render(<Carousel />);
}

