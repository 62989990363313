import { ThemeProvider } from '@emotion/react';
import SearchIcon from '@mui/icons-material/Search';
import { CssBaseline, IconButton, createTheme } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import InputAdornment from '@mui/material/InputAdornment';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import "ag-grid-community/styles/ag-grid.css"; // Core CSS
import "ag-grid-community/styles/ag-theme-quartz.css"; // Theme
import { parse } from 'csv-parse/browser/esm';
import { useEffect, useState } from 'react';



// const hideBar = (outerTheme) =>
//   createTheme({
//     palette: {
//       mode: outerTheme.palette.mode,
//     },
//     components: {

//       MuiInput: {
//         styleOverrides: {
//           root: ,
//         },
//       },
//     },
//   });

const darkTheme = (variant) => createTheme({
    typography: {
        fontFamily: 'Open Sans Hebrew, sans-serif',
    },
    components: {
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    fontSize: '1.2rem',
                    left: variant === 'outlined' ? 0 : 'unset',
                    right: variant === 'outlined' ? 'unset' : '60px',
                    top: variant === 'outlined' ? 'unset' : '-3px',
                    color: variant === 'outlined' ? 'unset' : 'white',
                    '&.Mui-focused': {
                        color: 'white',
                        left: 0,
                        top: 0,
                    }
                },

            }
        },
        MuiInput: {
            styleOverrides: {
                root: variant === 'outlined' ? {
                    fontSize: '1.5rem',

                } : {

                    '&::before': {
                        borderBottom: '0px ',
                    },
                    '&:hover:not(.Mui-disabled, .Mui-error):before': {
                        borderBottom: '0px ',
                    },
                    '&.Mui-focused:after': {
                        borderBottom: '0px ',
                    },
                }
            }
        },
        MuiIcon: {
            styleOverrides: {
                root: {
                    fontSize: '1.5rem',

                }
            }
        },
    },
    palette: {
        mode: 'dark',
        background: {
            default: "#17202C"
        }
    },
});



export const SearchExample = ({ variant }) => {

    const [allRowData, setAllRowData] = useState([]);
    const [q, setQ] = useState(null);
    const [open, setOpen] = useState(false);


    let ignore = false;
    useEffect(() => {
        if (!ignore) {
            startFetching();
        }
        return () => {
            ignore = true;
        }
    }, []);

    async function startFetching() {
        const response = await fetch("https://s3.amazonaws.com/webhosting.wellandlighthouse/Untitled.csv", { mode: 'cors' });
        const input = await response.text()
        const data = await new Promise((resolve, reject) => {
            parse(input, {
                columns: true,
            }, function (err, records) {
                if (err) {
                    reject(err);
                } else {
                    resolve(records);
                }
            });
        });

        setAllRowData(data);
    }

    function handleSearchClick() {

        if (q) {
            const encodedV = encodeURIComponent(q);
            window.location.href = `https://aeroaccessories.us/part-search-2/?q=${encodedV}`;

        }

    }

    function handleInput(e, v, r) {
        const encodedV = encodeURIComponent(v);
        if (r === 'reset' && !!v) {
            window.location.href = `https://aeroaccessories.us/part-search-2/?q=${encodedV}`;
        }
        setQ(v);
        if (!v) {
            setOpen(false);
        } else {
            setOpen(true);
        }
        // if (e) {
        //     setRowData(allRowData.filter(x => (x.description?.toLowerCase()?.indexOf(e?.toLowerCase()) != -1) || (x.partnum?.toLowerCase()?.indexOf(e?.toLowerCase()) != -1)))

        // } else {
        //     setRowData(allRowData);
        // }
    }

    return <>
        <ThemeProvider theme={darkTheme(variant)}>
            <CssBaseline />
            <Stack sx={{ width: variant === 'outlined' ? 'unset' : 350 }} spacing={2}>
                <Autocomplete

                    freeSolo
                    open={open}
                    componentsProps={{
                        paper: { sx: { backgroundColor: "rgba(23, 32, 44, 0.8)" } },
                    }}
                    filterOptions={(o, s) => o.filter(x => x.toLowerCase().includes(s.inputValue?.toLowerCase()) || x.toLowerCase().includes(s.inputValue?.toLowerCase())).slice(0, 5)}
                    onInputChange={(e, v, r) => handleInput(e, v, r)}
                    options={Array.from(new Set([...allRowData.map((row) => row['Product Description'])]))}
                    renderInput={(params) => (
                        <TextField
                            fullWidth={variant === 'outlined' ? true : false}
                            variant={variant}
                            {...params}
                            label="Search our Capabilities"
                            sx={{ backgroundColor: variant === 'outlined' ? 'rgba(23, 32, 44, 0.3)' : '' }}
                            onKeyDown={(ev) => {
                                if (ev.key == 'Enter') {
                                    handleSearchClick()
                                }
                            }}
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton onClick={handleSearchClick}>
                                            <SearchIcon fontSize='large' />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}

                        />
                    )}
                />

            </Stack>
        </ThemeProvider>
    </>
}