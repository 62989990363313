import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Alert from '@mui/material/Alert';
import Autocomplete from '@mui/material/Autocomplete';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import Snackbar from '@mui/material/Snackbar';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import "ag-grid-community/styles/ag-grid.css"; // Core CSS
import "ag-grid-community/styles/ag-theme-quartz.css"; // Theme
import { AgGridReact } from 'ag-grid-react'; // React Grid Logic
import { parse } from 'csv-parse/browser/esm';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import './table.css'


const darkTheme = createTheme({
    typography: {
        fontFamily: 'Open Sans Hebrew, sans-serif',
    },
    palette: {
        mode: 'dark',
        background: {
            default: "#17202C"
        }
    },
});


// enable cors on public
// mui
// upload build, add script and link tags to header on page
// rss to csv cron
// filtered result

const PartDialog = ({ onClose, selectedValue, open, dialogValue }) => {

    const { partnum, description } = dialogValue;
    const [snackbarOpen, setSnackbarOpen] = useState(false);

    const copyValue = (v) => {
        navigator.clipboard.writeText(v);
        setSnackbarOpen(true);
    }
    return (<>

        <Dialog onClose={() => onClose()} open={open}>

            <DialogTitle>More Info</DialogTitle>
            <Stack spacing={2} style={{ padding: '.5em 2em 2em 2em' }} >
                <>
                    <Typography variant="h6">Part Number</Typography>
                    <Stack direction="row" spacing={2}>
                        <Typography gutterBottom>{partnum}</Typography><Button onClick={() => copyValue(partnum)} variant="outlined">Copy
                            <ContentCopyIcon />
                        </Button>
                    </Stack>
                </>
                <>
                    <Typography variant="h6">Description</Typography>
                    <Stack direction="row" spacing={2}>
                        <Typography gutterBottom>{description}</Typography><Button onClick={() => copyValue(partnum)} variant="outlined">Copy
                            <ContentCopyIcon />
                        </Button>
                    </Stack>
                </>

                {/* <Button style={{ margin: '1em auto 0 0', width: 'fit-content' }} variant="contained">Request Part

                </Button> */}
            </Stack>
        </Dialog>


        <Snackbar open={snackbarOpen} onClose={() => setSnackbarOpen(false)} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} autoHideDuration={2000}>
            <Alert
                onClose={() => setSnackbarOpen(false)}
                severity="success"
                variant="filled"
                sx={{ width: '100%' }}
            >
                Copied
            </Alert>
        </Snackbar>
    </>

    )

}


export const GridExample = () => {

    const [allRowData, setAllRowData] = useState([]);
    const [rowData, setRowData] = useState([])
    const [q, setQ] = useState(null);
    const [cart, setCart] = useState([]);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [dialogValue, setDialogValue] = useState({});
    let ignore = false;

    const onGridReady = useCallback((params) => {
        async function startFetching() {
            const response = await fetch("https://s3.amazonaws.com/webhosting.wellandlighthouse/Untitled.csv", { mode: 'cors' });
            const input = await response.text()
            const data = await new Promise((resolve, reject) => {
                parse(input, {
                    columns: true,
                }, function (err, records) {
                    if (err) {
                        reject(err);
                    } else {
                        resolve(records);
                    }
                });
            });

            setAllRowData(data);
            setRowData(data);
            const urlParams = new URLSearchParams(window.location.search);
            const activeParams = {};
            for (const [key, value] of urlParams.entries()) {
                activeParams[key] = value;
            }
            console.log(activeParams);
            if (activeParams.q) {
                const e = activeParams.q ?? '';
                setQ(e);
                setRowData(data.filter(x => (JSON.stringify(x).toLowerCase().includes(e?.toLowerCase() ?? ''))))

            }

            // const urlParams = new URLSearchParams(window.location.search);
            // const activeParams = {};
            // for (const [key, value] of urlParams.entries()) {
            //     activeParams[key] = value;
            // }
            // console.log(activeParams);
            // if (activeParams.q) {
            //     handleInput(activeParams.q);
            // }
        }
        startFetching();

    }, []);

    const onDataRender = useCallback((params) => {
        const mobile = window.innerWidth < 900;
        const allColumnIds = [];
        gridRef.current.api.getColumns().forEach((column) => {
            allColumnIds.push(column.getId());
        });
        if (mobile) {

            gridRef.current.api.autoSizeAllColumns();
        }
        gridRef.current.api.autoSizeAllColumns();
        console.log('data render')

    }, []);



    const gridRef = useRef();
    // const [rowData, setRowData] = useState([

    // ]);

    // const sizeToFit = useCallback(() => {
    //     gridRef.current.api.sizeColumnsToFit({
    //         defaultMinWidth: 100,
    //         columnLimits: [{ key: 'Form description', minWidth: 200 }],
    //     });
    // }, []);

    const autoSizeStrategy = useMemo(() => {
        const mobile = window.innerWidth < 900;
        return {
            type: mobile ? 'fitCellContents' : 'fitCellContents',
        };
    }, []);

    const autoSizeAll = useCallback((skipHeader) => {
        const allColumnIds = [];
        gridRef.current.api.getColumns().forEach((column) => {
            allColumnIds.push(column.getId());
        });
        gridRef.current.api.autoSizeColumns(allColumnIds, skipHeader);
    }, []);

    function handleInput(e) {
        setQ(e);
        console.log({ e, count: allRowData.length })
        if (e) {
            setRowData(allRowData.filter(x => (JSON.stringify(x).toLowerCase().includes(e?.toLowerCase() ?? ''))))

        } else {
            setRowData(allRowData);
        }
    }

    useEffect(() => {
        if (!ignore) {
            if (localStorage.getItem('aeropartcart')) {
                console.log(JSON.parse(localStorage.getItem('aeropartcart')));
                setCart(JSON.parse(localStorage.getItem('aeropartcart')))
            }
        }
        return () => {
            ignore = true;
        }
    }, [])

    useEffect(() => {
        localStorage.setItem('aeropartcart', JSON.stringify(cart))
    }, [cart]);


    const handleCartClick = (e, params) => {
        const o = {};
        console.log({ cart })
        for (const row of cart) {
            o[row.partnum] = row.count;
        }

        if (o[params.data.partnum]) {
            o[params.data.partnum] += 1;
        } else {
            o[params.data.partnum] = 1;
        }
        setCart(Object.entries(o).map(([partnum, count]) => ({ partnum, count })));
        setSnackbarOpen(true);
    }

    const handleMoreInfoClick = (e, params) => {
        setDialogValue(params.data);
        setDialogOpen(true);
    }


    // Column Definitions: Defines & controls grid columns.
    const colDefs = useMemo(() => {

        return [
            // { field: "idx", headerName: 'Index', cellDataType: 'text', width: 100 },
            { field: "Product Name", headerName: 'Product Name', cellDataType: 'text' },
            { field: "Product Description", headerName: 'Product Description', cellDataType: 'text' },
            { field: "Part Manufacturer", headerName: 'Part Manufacturer', cellDataType: 'text' },
            { field: "ATA Chapter", headerName: 'ATA Chapter', cellDataType: 'text' },
            { field: "Aircraft Type", headerName: 'Aircraft Type', cellDataType: 'text' },
            { field: "Aircraft Manufacturer", headerName: 'Aircraft Manufacturer', cellDataType: 'text' },
            // {
            //     field: "partnum", headerName: '', width: 250, cellRenderer: (params) => {
            //         return <Button onClick={(e) => handleCartClick(e, params)} variant="outlined">Add to Cart</Button>
            //     }
            // },
            // {
            //     field: "partnum", headerName: '', width: 250, cellRenderer: (params) => {
            //         return <Button size="small" onClick={(e) => handleMoreInfoClick(e, params)} variant="outlined">More Info</Button>
            //     }
            // },

        ]
    });



    return (
        <>

            <ThemeProvider theme={darkTheme}>
                <CssBaseline />

                {/* <List>

                {cart.map(({ partnum, count }, i) =>
                    <ListItem key={i} disablePadding><ListItemButton><ListItemText primary={`${partnum}: ${count}`}></ListItemText></ListItemButton></ListItem>
                )}

            </List> */}
                <Stack sx={{ width: 350 }} spacing={4}>

                    <Autocomplete
                        style={{ margin: '1em 0 0 20px' }}
                        freeSolo
                        onInputChange={(e, v) => handleInput(v)}
                        options={Array.from(new Set([...allRowData.map((row) => row['Product Description'])]))}
                        renderInput={(params) => <TextField {...params} label="Search" />}
                    />
                    <div></div>

                </Stack>

                <div className="ag-theme-quartz-dark" style={{ height: `73vh` }}>
                    {/* The AG Grid component */}
                    <AgGridReact ref={gridRef} autoSizeStrategy={autoSizeStrategy} onRowDataUpdated={onDataRender} overlayNoRowsTemplate={`No Parts found for "${q}"`}
                        pagination={false} suppressColumnVirtualisation={true}
                        onGridReady={onGridReady} rowData={rowData} columnDefs={colDefs} />
                </div>



                <Snackbar open={snackbarOpen} onClose={() => setSnackbarOpen(false)} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} autoHideDuration={2000}>
                    <Alert
                        onClose={() => setSnackbarOpen(false)}
                        severity="success"
                        variant="filled"
                        sx={{ width: '100%' }}
                    >
                        Added to Cart
                    </Alert>
                </Snackbar>

                <PartDialog open={dialogOpen} dialogValue={dialogValue} onClose={() => setDialogOpen(false)}></PartDialog>
            </ThemeProvider>
        </>
    )

}