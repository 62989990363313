import React from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { CssBaseline, Grid, IconButton, Stack, createTheme } from '@mui/material';
import { ThemeProvider } from '@emotion/react';
import { useEffect, useState } from 'react';
import './contactform2.css';

const darkTheme = createTheme({
    typography: {
        fontFamily: 'Open Sans Hebrew, sans-serif',
    },
    palette: {
        // mode: 'dark',
        // background: {
        //     default: "#17202C"
        // }
    },
});


export const ContactForm2 = () => {

    const [message, setMessage] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();
        const formData = new FormData(e.target);
        formData.append('_wpcf7_unit_tag', 'wpcf7-f3671-p3671-o1');
        formData.append('_wpcf7', '3671');
        console.log(formData);
        fetch('/wp-json/contact-form-7/v1/contact-forms/3671/feedback', {
            'method': 'POST',
            'body': formData
        }).then(response => {
            response.json().then(data => {
                console.log(data);
                setMessage(data.message);
                setTimeout(() => {
                    setMessage('');
                }, 50000)
            })
        });
        // Handle form submission logic here
    };

    return (
        <ThemeProvider theme={darkTheme}>
            <CssBaseline />

            <form className='contactform2' onSubmit={handleSubmit}>
                <Grid container spacing={2} sx={{ p: 2 }}>
                    <Grid item xs={6}>
                        <TextField
                            label="First"
                            required
                            variant="filled"
                            name="your-first"
                            fullWidth
                            margin="normal"
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            label="Last"
                            variant="filled"
                            required
                            name="your-last"
                            fullWidth
                            margin="normal"
                        />
                    </Grid>
                    <Grid item xs={6}>

                        <TextField
                            label="Email"
                            variant="filled"
                            name="your-email"
                            required
                            fullWidth
                            margin="normal"
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            label="Phone"
                            variant="filled"
                            name="your-phone"
                            fullWidth
                            margin="normal"
                        />
                    </Grid>
                    <Grid item xs={12}>

                        <TextField
                            label="Message"
                            variant="filled"
                            name="your-message"
                            fullWidth
                            margin="normal"
                            multiline
                            rows={4}
                        />
                    </Grid>

                    <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                        <Button type="submit" className='reactsubmitbutton' variant='text'>
                            +&nbsp;Submit
                        </Button>
                    </Grid>
                    <Grid item xs={12}>
                        {message && <p >{message}</p>}
                    </Grid>
                </Grid>
            </form>


        </ThemeProvider >
    );
};
