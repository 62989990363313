import { useEffect, useState } from "react";
import './carousel.css';

export const Carousel = () => {

    const [active, setActive] = useState(0);
    const [intervalId, setIntervalId] = useState(null);
    // const imgs = [
    //     'https://aeroaccessories.us/wp-content/uploads/2024/04/hero-ver-1.2-webp.webp',
    //     'https://aeroaccessories.us/wp-content/uploads/2024/03/SELECTS-1-01-26-21.png',
    //     'https://aeroaccessories.us/wp-content/uploads/2024/03/SELECTS-1-14-07-01.png',
    //     'https://aeroaccessories.us/wp-content/uploads/2024/03/SELECTS-1-17-58-04.png',
    // ]
    const imgs = [
        'https://aeroaccessories.us/wp-content/uploads/2024/06/Slide-1.webp',
        'https://aeroaccessories.us/wp-content/uploads/2024/06/SELECTS-1-03-57-16.png',
        'https://aeroaccessories.us/wp-content/uploads/2024/06/Slide-3.webp',
        'https://aeroaccessories.us/wp-content/uploads/2024/03/SELECTS-1-17-58-04.png',
    ]
    const headings = [
        'A Leader in Advanced Aerospace Component Repair Solutions',
        'Where Expertise and Technology Meet',
        'Cutting-edge Technologies',
        'Where You Can Find Us',

    ];
    const bodyText = [
        'Leveraging decades of experience, we provide advanced repair solutions for next-generation fuel, hydraulic, pneumatic, avionics, and electro-mechanical components. We cover 17,000 part numbers and support over 25 ATA chapters with premier quality and service.',
        'Founded by industry veterans, Aero Accessories is committed to recruiting and retaining the most skilled professionals. Our team of 120 experts has decades of combined experience in component maintenance and engineering. ',
        'We differentiate ourselves as a leader in component maintenance through a continuing investment in cutting-edge technologies — including the most advanced Avtron test stand available — and a high-quality system that meets industry benchmarks to ensure every component that leaves our facility is of the highest quality.',
        'Located in Miramar, Florida, we operate an 80,000-square-foot, environmentally controlled, state-of-the-art facility with 2,000 amp power service to support all component testing requirements.'
    ]
    useEffect(() => {
        const interval = setInterval(() => {
            if (window.innerWidth < 1024) {
                setActive(0);
                clearInterval(interval);
                return;
            }
            setActive(active => (active + 1) % imgs.length);
        }, 15_000);
        setIntervalId(interval);

    }, []);

    useEffect(() => {
        const el = document.querySelector('#imagecontainer');
        // el.style['backgroundImage'] = active == 0 ? `linear-gradient(rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0), url(${imgs[active]})` : `url(${imgs[active]})`;
        el.style['backgroundImage'] = `url(${imgs[active]})`;
        const headingEl = document.querySelector('#headingcontainer').querySelector('h2');
        const textEl = document.querySelector('#textcontainer').querySelector('p');
        headingEl.innerText = headings[active];
        textEl.innerText = bodyText[active];

    }, [active]);

    const selectIndex = (i) => {
        setActive(i);
        console.log('clearing interval');
        clearInterval(intervalId);
    }


    return (
        <>
            <div id="carouselContainer">
                {imgs.map((img, index) => <div onClick={() => selectIndex(index)} className={active === index ? "carousel-icon carousel-icon-active" : "carousel-icon"} key={index}></div>)}
            </div>
        </>
    )
}